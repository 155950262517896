import React, { useEffect, useState } from "react";
import EverlandSanrio1 from "../assets/imgs/everland_sanrio_1.jpg";
import EverlandSanrio2 from "../assets/imgs/everland_sanrio_2.jpg";
import EverlandSanrio3 from "../assets/imgs/everland_sanrio_3.jpg";
import EverlandSanrio4 from "../assets/imgs/everland_sanrio_4.jpg";
import EverlandSanrio5 from "../assets/imgs/everland_sanrio_5.jpg";
import EverlandSanrio6 from "../assets/imgs/everland_sanrio_6.jpg";
import EverlandSanrio7 from "../assets/imgs/everland_sanrio_7.jpg";
import EverlandSanrio8 from "../assets/imgs/everland_sanrio_8.jpg";
import EverlandSanrio9 from "../assets/imgs/everland_sanrio_9.jpg";
import EverlandSanrio10 from "../assets/imgs/everland_sanrio_10.jpg";

const ImageBox = () => {
  const IMGLIST = [EverlandSanrio1, EverlandSanrio2, EverlandSanrio3, EverlandSanrio4, EverlandSanrio5, EverlandSanrio6, EverlandSanrio7, EverlandSanrio8, EverlandSanrio9, EverlandSanrio10];
  const [imgFile, setImgFile] = useState(EverlandSanrio1);
  var imgIndex = 0;

  useEffect(() => {
    const interval = setInterval(() => {
      setImgFile(IMGLIST[imgIndex++]);
      if (imgIndex > 9)
        imgIndex = 0;
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="flex h-full w-full">
      <img src={imgFile} className="w-full h-full" />
    </div>
  );
};

export default ImageBox;
