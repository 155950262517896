import "../App.css";
import Topbar from "./Topbar";
import TextContent from "./TextContents";

import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";

export default function Main() {
  const navigate = useNavigate();

  const sectionService = useRef(null);
  const sectionStory = useRef(null);
  const sectionOurTeam = useRef(null);
  const sectionVision = useRef(null);
  const sectionPartners = useRef(null);
  const sectionGallery = useRef(null);
  const sectionLogin = useRef(null);

  const gotoTop = () => {
    window.scrollTo({ top: window.top, behavior: "smooth" });
  };

  const gotoServiceSection = () =>
    window.scrollTo({
      top: sectionService.current.offsetTop,
      behavior: "smooth",
    });

  const gotoStorySection = () =>
    window.scrollTo({
      top: sectionStory.current.offsetTop,
      behavior: "smooth",
    });

  const gotoOurTeamSection = () =>
    window.scrollTo({
      top: sectionOurTeam.current.offsetTop,
      behavior: "smooth",
    });

  const gotoVisionSection = () =>
    window.scrollTo({
      top: sectionVision.current.offsetTop,
      behavior: "smooth",
    });

  const gotoPartnersSection = () =>
    window.scrollTo({
      top: sectionPartners.current.offsetTop,
      behavior: "smooth",
    });

  const gotoGallerySection = () =>
    window.scrollTo({
      top: sectionGallery.current.offsetTop,
      behavior: "smooth",
    });

  const gotoShopPage = () => {
    if (localStorage.getItem("kts_autoLogin") === null) {
      navigate("/shop/login");
    } else {
      navigate("/shop/main");
    }
  };

  const gotoMedicalPage = () => {
    navigate("/medical");
  };

  const gotoReservationPage = () => {
    navigate("/reservation");
  };

  return (
    <div className="container">
      <div className="App">
        <Topbar
          gotoTop={gotoTop}
          gotoServiceSection={gotoServiceSection}
          gotoStorySection={gotoStorySection}
          gotoOurTeamSection={gotoOurTeamSection}
          gotoVisionSection={gotoVisionSection}
          gotoPartnersSection={gotoPartnersSection}
          gotoGallerySection={gotoGallerySection}
          gotoShopPage={gotoShopPage}
          gotoMedicalPage={gotoMedicalPage}
          gotoReservationPage={gotoReservationPage}
        />
      </div>
      <TextContent
        sectionService={sectionService}
        sectionStory={sectionStory}
        sectionOurTeam={sectionOurTeam}
        sectionVision={sectionVision}
        sectionPartners={sectionPartners}
        sectionGallery={sectionGallery}
        sectionLogin={sectionLogin}
      />
    </div>
  );
}
